import React from 'react';

class index extends React.Component {
    
    render() {
        return (
            <>
            </>
        );
    }

};

export default index;
