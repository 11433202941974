import React from 'react';
import _Loket from './_Loket';

class Dalam extends React.Component {
    
    render() {
        return (
            <_Loket
                id_pelpoli={5}
                nama_pelpoli="P. Dalam"
            ></_Loket>
        );
    }

};

export default Dalam;
