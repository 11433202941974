import React from 'react';
import socketIOClient from "socket.io-client";
import { Card } from 'antd';
import ReactPlayer from 'react-player';

class index extends React.Component {
    
    state = {
        socket: socketIOClient(process.env.REACT_APP_URL_API_SIMRS),
        aktif: {
            kode: null,
            loket: null,
            nomorantrean: null,
            url: [],
        },

        loket1: null,
        loket2: null,
        loket3: null,
        loket4: null,
        loket5: null,

        url: [],
        index: 0,
        playing: false,
        pencet: false,
    }
    
    componentDidMount() {
        this.state.socket.on('PanggilAntreanPendaftaran', aktif => {
            
            const url = [require("../../../../assets/audios/antrean/nomor_antrian.mp3")];
            url.push(require(`../../../../assets/audios/antrean/${aktif.kode}.mp3`));
            JSON.stringify(aktif.nomorantrean).split('').forEach((row, i) => {
                url.push(require(`../../../../assets/audios/antrean/${row}.mp3`));
            });
            url.push(require(`../../../../assets/audios/antrean/diloket_${aktif.loket}.mp3`));

            if (aktif.loket === 1) {
                this.setState({ loket1: `${aktif.kode}-${aktif.nomorantrean}` });
            };
            if (aktif.loket === 2) {
                this.setState({ loket2: `${aktif.kode}-${aktif.nomorantrean}` });
            };
            if (aktif.loket === 3) {
                this.setState({ loket3: `${aktif.kode}-${aktif.nomorantrean}` });
            };
            if (aktif.loket === 4) {
                this.setState({ loket4: `${aktif.kode}-${aktif.nomorantrean}` });
            };
            if (aktif.loket === 5) {
                this.setState({ loket5: `${aktif.kode}-${aktif.nomorantrean}` });
            };
            
            this.setState({ aktif : { ...aktif, url } }, () => {
                if (this.state.playing) {
                    return;
                } else {
                    this.holdSong();
                };
            });

		});
	}
    
    componentWillUnmount() {
        this.state.socket.disconnect();
    }

    holdSong = () => {
        this.setState({ url: this.state.aktif.url, index: 0, playing: true });
    }

    playingPlay = () => {
        this.setState({ playing: true });
    }
    
    render() {
        const { aktif, loket1, loket2, loket3, loket4, loket5 } = this.state;
        return (
            <>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div className="text-9xl text-center mb-3 text-red-500 font-bold border border-red-500">LOKET {aktif.loket}</div>
                        <Card className="font-bold bg-yellow-300 flex justify-center" style={{ fontSize: 200 }}>
                            {aktif.kode}-{aktif.nomorantrean}
                        </Card>
                    </div>
                    <div>
                        <div className="text-9xl text-center mb-3 text-red-500 font-bold border border-red-500">INFO</div>
                        <div className="text-6xl bg-yellow-300 flex justify-between p-3 mb-3">
                            <span>LOKET 1</span>
                            <span className="font-bold">{loket1}</span>
                        </div>
                        <div className="text-6xl bg-yellow-300 flex justify-between p-3 mb-3">
                            <span>LOKET 2</span>
                            <span className="font-bold">{loket2}</span>
                        </div>
                        <div className="text-6xl bg-yellow-300 flex justify-between p-3 mb-3">
                            <span>LOKET 3</span>
                            <span className="font-bold">{loket3}</span>
                        </div>
                        <div className="text-6xl bg-yellow-300 flex justify-between p-3 mb-3">
                            <span>LOKET 4</span>
                            <span className="font-bold">{loket4}</span>
                        </div>
                        <div className="text-6xl bg-yellow-300 flex justify-between p-3 mb-3">
                            <span>LOKET 5</span>
                            <span className="font-bold">{loket5}</span>
                        </div>
                    </div>
                </div>

                <div className="hidden">
                    <ReactPlayer
                        key={this.state.index}
                        url={this.state.url[this.state.index]}
                        className="border border-black"
                        controls={true}
                        playing={this.state.playing}
                        onEnded={() => {
                            const indexPrev = this.state.index;
                            const indexEnd = this.state.url.length-1;
                            if (indexPrev === indexEnd) {
                                this.setState({ playing: false, index: 0 });
                            } else {
                                this.setState({ index: indexPrev + 1 });
                            };
                        }}
                    />
                </div>
            </>
        );
    }

};

export default index;
