import React from 'react';
import { Divider } from 'antd';

class Footer extends React.Component {
	
	render() {
		return (
			<>
				<footer className="fixed bottom-0 w-full p-5">
					<Divider />
					<div>FOOTER</div>
				</footer>
			</>
		)
	}

}

export default Footer;
