import React from 'react';
import { useParams, Link } from "react-router-dom";
import { Card, Button } from 'antd';

const ColumnCustom = ({ data }) => (
    <Link to={data.link ? data.link : ''}>
        <Card
            hoverable
            style={{ fontSize: '4vw', fontWeight: 'bold' }}
            className={`${data.color} text-center h-full`}
        >
            {data.name}
        </Card>
    </Link>
);

const Index = () => {
    
    const { id_antrean } = useParams();
    
    return (
        <>
            <Button type="link" href="/#/checkin" className="border border-black w-full mb-5 bg-red-500 text-white font-bold">Kembali</Button>
            <div className="grid grid-cols-3 gap-4">
                <ColumnCustom
                    data={{
                        link: `/checkin/${id_antrean}/bpjs/kontrol/konsul`,
                        name: 'KONTROL KONSUL DOKTER',
                        color: 'bg-blue-200',
                    }}
                />
                <ColumnCustom
                    data={{
                        // link: `/checkin/${id_antrean}/bpjs/kontrol/poli`,
                        name: 'REHAB MEDIK',
                        color: 'bg-green-200',
                    }}
                />
                <ColumnCustom
                    data={{
                        // link: `/checkin/${id_antrean}/bpjs/kontrol/poli`,
                        name: 'HEMODIALISA',
                        color: 'bg-yellow-200',
                    }}
                />
                <ColumnCustom
                    data={{
                        // link: `/checkin/${id_antrean}/bpjs/kontrol/inap`,
                        name: 'KONTROL PASCA RAWAT INAP',
                        color: 'bg-red-200',
                    }}
                />
            </div>
        </>
    );

};

export default Index;
