import React from 'react';
import { Input, Modal, Divider } from 'antd';

class PasienBaru extends React.Component {
    
    render() {
        const { dataPasien, onClose, onChangeDataPasien } = this.props;
        const { tempat_lahir, agama, pendidikan, status_pernikahan, wilayah, alamat, hp } = dataPasien;
        
        return (
            <Modal
                visible={true}
                onCancel={onClose}
                style={{
                    top: 20,
                }}
                width={1500}
                footer={false}
            >
                <div className="grid gap-4 font-bold text-2xl">
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <div>Tempat Lahir</div>
                            <Input
                                value={tempat_lahir}
                                size="large"
                                className="border border-black"
                                onChange={(e) => onChangeDataPasien('tempat_lahir', e.target.value.toUpperCase())}
                            />
                            <Divider />
                        </div>
                        <div>
                            <div>Alamat</div>
                            <Input.TextArea
                                size="large"
                                style={{ height: 40 }}
                                className="border border-black"
                                value={alamat}
                                onChange={(e) => onChangeDataPasien('alamat', e.target.value.toUpperCase())}
                            />
                            <Divider />
                        </div>
                    </div>
                    <div>
                        <div>Agama</div>
                            {/* <Option value="ISLAM">ISLAM</Option>
                            <Option value="KATOLIK">KATOLIK</Option>
                            <Option value="PROTESTAN">PROTESTAN</Option>
                            <Option value="BUDHA">BUDHA</Option>
                            <Option value="HINDU">HINDU</Option>
                            <Option value="KONGHUCHU">KONGHUCHU</Option> */}
                        <div className="grid grid-cols-6 gap-2">
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "ISLAM" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'ISLAM')}
                            >ISLAM</span>
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "KATOLIK" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'KATOLIK')}
                            >KATOLIK</span>
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "PROTESTAN" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'PROTESTAN')}
                            >PROTESTAN</span>
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "BUDHA" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'BUDHA')}
                            >BUDHA</span>
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "HINDU" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'HINDU')}
                            >HINDU</span>
                            <span
                                className={`border border-green-500 h-24 grid place-content-center ${agama === "KONGHUCHU" ? 'bg-green-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('agama', 'KONGHUCHU')}
                            >KONGHUCHU</span>
                        </div>
                        <Divider />
                    </div>
                    <div>
                        <div>Pendidikan</div>
                        {/* <Option value={1}>Tidak Sekolah</Option>
                        <Option value={2}>Sd</Option>
                        <Option value={3}>Smp</Option>
                        <Option value={4}>Sma</Option>
                        <Option value={5}>Diploma</Option>
                        <Option value={6}>Sarjana</Option>
                        <Option value={7}>Magister</Option>
                        <Option value={8}>Doktor</Option>
                        <Option value={9}>Profesor</Option> */}
                        <div className="grid grid-cols-9 gap-2">
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 1 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 1)}
                            >TIDAK SEKOLAH</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 2 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 2)}
                            >SD</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 3 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 3)}
                            >SMP</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 4 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 4)}
                            >SMA</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 5 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 5)}
                            >DIPLOMA</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 6 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 6)}
                            >SARJANA</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 7 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 7)}
                            >MAGISTER</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 8 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 8)}
                            >DOKTOR</span>
                            <span
                                className={`border border-yellow-500 h-24 grid place-content-center text-center ${pendidikan === 9 ? 'bg-yellow-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('pendidikan', 9)}
                            >PROFESOR</span>
                        </div>
                        <Divider />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <div>Status Pernikahan</div>
                            {/* <Option value="M">MENIKAH</Option>
                            <Option value="B">BELUM MENIKAH</Option> */}
                            <div className="grid grid-cols-2 gap-2">
                                <span
                                    className={`border border-red-500 h-24 grid place-content-center ${status_pernikahan === "M" ? 'bg-red-500 text-white' : ''}`}
                                    onClick={() => onChangeDataPasien('status_pernikahan', 'M')}
                                >MENIKAH</span>
                                <span
                                    className={`border border-red-500 h-24 grid place-content-center ${status_pernikahan === "B" ? 'bg-red-500 text-white' : ''}`}
                                    onClick={() => onChangeDataPasien('status_pernikahan', 'B')}
                                >BELUM MENIKAH</span>
                            </div>
                        </div>
                        <div>
                            <div>No. Handphone</div>
                            <Input
                                value={hp}
                                size="large"
                                className="border border-black"
                                onChange={(e) => onChangeDataPasien('hp', e.target.value)}
                            />
                        </div>
                        <Divider />
                    </div>
                    <div>
                        <div>Wilayah</div>
                        {/* <Option value={1}>Dalam Banda Aceh</Option>
                        <Option value={2}>Luar Banda Aceh</Option> */}
                        <div className="grid grid-cols-2 gap-2">
                            <span
                                className={`border border-blue-500 h-24 grid place-content-center ${wilayah === 1 ? 'bg-blue-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('wilayah', 1)}
                            >DALAM BANDA ACEH</span>
                            <span
                                className={`border border-blue-500 h-24 grid place-content-center ${wilayah === 2 ? 'bg-blue-500 text-white' : ''}`}
                                onClick={() => onChangeDataPasien('wilayah', 2)}
                            >LUAR BANDA ACEH</span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

};

export default (PasienBaru);
