import React from 'react';
import { Button, Divider } from 'antd';
import Jam from '../Jam';

class Header extends React.Component {
	
	render() {
		return (
			<>
				<header className="fixed w-full top-0 p-5">
					<div className="flex justify-between">
						<div>
							HEADER
						</div>
						<div>
							<Jam />
						</div>
						<div>
							<Button type="link" href="/" className="border border-black">Kembali</Button>
						</div>
					</div>
					<Divider />
				</header>
			</>
		)
	}

}

export default Header;
