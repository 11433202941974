import React from 'react';
import _Loket from './_Loket';

class Obgyn extends React.Component {
    
    render() {
        return (
            <_Loket
                id_pelpoli={8}
                nama_pelpoli="Obgyn"
            ></_Loket>
        );
    }

};

export default Obgyn;
