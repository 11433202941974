import axios from 'axios';

const ApiSimrs = axios.create({
	baseURL: process.env.REACT_APP_URL_API_SIMRS,
});

ApiSimrs.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tokenSimrs');
    if (token) {
      config.headers['token'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default ApiSimrs;
