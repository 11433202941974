import React from 'react';
import { Input, Card, Modal, Button } from 'antd';
import { Link } from "react-router-dom";
import {
    ForwardOutlined,
} from '@ant-design/icons';
import Antrean from "../../components/CetakAntrean";
import ApiSimrs from '../../config/ApiSimrs';
import moment from "moment";

class index extends React.Component {
    
    state = {
        loading: false,
        openAntrean: false,
        data: {},
    }
    
    fetchAntrean = (kodebooking) => {
        this.setState({
            loading: true
        }, () => {
            ApiSimrs.get(`/antrean/kodebooking/${kodebooking}`)
            .then((result) => {
                if (result.data !== '') {
                    this.setState({
                        data: {
                            ...result.data,
                            tanggal: moment(result.data.tgl).format('dddd, Do MMMM YYYY, HH:mm:ss')
                        },
                        loading: false,
                        openAntrean: true,
                    });
                } else {
                    this.setState({
                        loading: false,
                        openAntrean: false,
                    }, () => {
                        Modal.warn({
                            content: `Kodebooking ${kodebooking} tidak ditemukan.`
                        });
                    });
                };
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    openAntrean: false,
                }, () => {
                    Modal.warn({
                        content: error.response.data
                    });
                });
            });
        });
    }
    
    render() {
        return (
            <>
                <Button type="link" href="/#/checkin" className="border border-black w-full mb-5 bg-red-500 text-white font-bold">Kembali</Button>
                <Input.Search
                    className="w-full mb-2"
                    placeholder="Masukkan Kode Booking ..."
                    size="large"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length === 13) {
                            this.fetchAntrean(value);
                        };
                    }}
                    autoFocus
                    maxLength={13}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                />
                {
                    this.state.openAntrean ?
                        <>
                            <Card className="mb-2">
                                <Antrean
                                    data={this.state.data}
                                    tampil={true}
                                />
                            </Card>
                            <Link to={`/checkin/${this.state.data.id_antrean}/bpjs`}>
                                <Card hoverable className="bg-blue-500 text-white">
                                    <div className="flex justify-between">
                                        <div style={{ fontSize: '35px' }}><b>LANJUT</b></div>
                                        <div><ForwardOutlined style={{ fontSize: '50px' }} /></div>
                                    </div>
                                </Card>
                            </Link>
                        </>
                    : null
                }
            </>
        );
    };

};

export default (index);
