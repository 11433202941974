import React, { useRef } from 'react';
import { Input, Modal, Card, Button } from 'antd';
import ReactToPrint from 'react-to-print';
import CetakSep from '../../../../components/CetakSep';
import { useNavigate, useParams } from "react-router-dom";
import ApiSimrs from '../../../../config/ApiSimrs';
import Antrean from "../../../../components/CetakAntrean";
import PasienBaru from "./PasienBaru";
import { nikParser } from 'nik-parser'

const withNavigate = Component => props => {
    const navigate = useNavigate();
    const componentRef = useRef();
    const { id_antrean } = useParams();
    return <Component
        {...props}
        navigate={navigate}
        componentRef={componentRef}
        id_antrean={id_antrean}
    />;
};

const dataBpjsRujukanTmp = {
    diagnosa: {
       kode: null,
       nama: null
    },
    keluhan: null,
    noKunjungan: null,
    pelayanan: {
       kode: null,
       nama: null
    },
    peserta: {
        cob: {
            nmAsuransi: null,
            noAsuransi: null,
            tglTAT: null,
            tglTMT: null
        },
        hakKelas: {
            keterangan: null,
            kode: null
        },
        informasi: {
            dinsos: null,
            noSKTM: null,
            prolanisPRB: null
        },
        jenisPeserta: {
            keterangan: null,
            kode: null
        },
        mr: {
            noMR: null,
            noTelepon: null
        },
        nama: null,
        nik: null,
        noKartu: null,
        pisa: null,
        provUmum: {
            kdProvider: null,
            nmProvider: null
        },
        sex: null,
        statusPeserta: {
            keterangan: null,
            kode: null
        },
        tglCetakKartu: null,
        tglLahir: null,
        tglTAT: null,
        tglTMT: null,
        umur: {
            umurSaatPelayanan: null,
            umurSekarang: null
        }
    },
    poliRujukan: {
       kode: null,
       nama: null
    },
    provPerujuk: {
       kode: null,
       nama: null
    },
    tglKunjungan: null
};
const dataAntreanTmp = {
    poli_bpjs: null,
    dokter_bpjs: null,
    nama_dokter: null,
    kode: null,
    nomorantrean: null,
};
const dataPasienTmp = {
    no_rekmedis: null,
    tempat_lahir: null,
    agama: null,
    pendidikan: null,
    status_pernikahan: null,
    wilayah: null,
    alamat: null,
    kabupaten: null,
    kecamatan: null,
    nama_pelpasien: null,
    hp: null,
};
const dataBpjsPesertaTmp = {
    cob:{
       nmAsuransi:null,
       noAsuransi:null,
       tglTAT:null,
       tglTMT:null
    },
    hakKelas:{
       keterangan: null,
       kode: null
    },
    informasi:{
       dinsos:null,
       noSKTM:null,
       prolanisPRB:null
    },
    jenisPeserta:{
       keterangan:null,
       kode: null
    },
    mr:{
       noMR:null,
       noTelepon:null
    },
    nama: null,
    nik: null,
    noKartu: null,
    pisa: null,
    provUmum:{
       kdProvider: null,
       nmProvider: null
    },
    sex: null,
    statusPeserta:{
       keterangan: null,
       kode: null
    },
    tglCetakKartu: null,
    tglLahir: null,
    tglTAT: null,
    tglTMT: null,
    umur:{
       umurSaatPelayanan: null,
       umurSekarang: null
    }
};
const dataBpjsSepCariTmp = {
    noSep: null,
    tglSep: null,
    jnsPelayanan: null,
    kelasRawat: null,
    diagnosa: null,
    noRujukan: null,
    poli: null,
    poliEksekutif: null,
    catatan: null,
    penjamin: null,
    kdStatusKecelakaan: null,
    nmstatusKecelakaan: null,
    lokasiKejadian: {
        kdKab: null,
        kdKec: null,
        kdProp: null,
        ketKejadian: null,
        lokasi: null,
        tglKejadian: null
    },
    dpjp: {
        kdDPJP: null,
        nmDPJP: null
    },
    peserta: {
        asuransi: null,
        hakKelas: null,
        jnsPeserta: null,
        kelamin: null,
        nama: null,
        noKartu: null,
        noMr: null,
        tglLahir: null
    },
    klsRawat: {
        klsRawatHak: null,
        klsRawatNaik: null,
        pembiayaan: null,
        penanggungJawab: null
    },
    kontrol: {
        kdDokter: null,
        nmDokter: null,
        noSurat: null
    },
    cob: null,
    katarak: null
};
const dataCheckinTmp = {
    nomorantrean: null,
};

const pageStyle = `
    tr:nth-child(odd) {
        background-color: #ccc;
    }
    tr:nth-child(even) {
        background-color: #fff;
    }
`;

class index extends React.Component {
    
    state = {
        dataAntrean: dataAntreanTmp,
        dataBpjsRujukan: dataBpjsRujukanTmp,
        dataBpjsSepCari: dataBpjsSepCariTmp,
        dataBpjsPeserta: dataBpjsPesertaTmp,
        dataPasien: dataPasienTmp,
        dataCheckin: dataCheckinTmp,

        bpjs_no_rujukan: null,
        status_pasien: null,

        loading: false,
        openPasienBaru: false,
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiSimrs.get(`/antrean/${this.props.id_antrean}`)
            .then((result) => {
                // console.log("dataAntrean====", result.data)
                this.setState({
                    dataAntrean: result.data,
                    dataCheckin: {
                        nomorantrean: `${result.data.kode}-${result.data.nomorantrean}`
                    },
                    loading: false,
                });
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Warning',
                    content: error.response.data
                });
            });
        });
    }

    kaloError = (data) => {
        this.setState({
            loading: false,
        }, () => {
            Modal.warn({
                content: data,
                onOk: () => this.setState({ dataBpjsRujukan: dataBpjsRujukanTmp }),
            });
        });
    }

    fetchRujukan = (bpjs_no_rujukan) => {
        this.setState({ loading: true }, () => {
            ApiSimrs.get(`/bpjs/rujukan/norujukan/pc/${bpjs_no_rujukan}`, { headers: { jenis_faskes: '1' } })
            .then((result) => {
                const dataBpjsRujukan = result.data;
                const nikParser2 = nikParser(dataBpjsRujukan.peserta.nik)
                let dataPasien = this.state.dataPasien;
                dataPasien['kabupaten'] = nikParser2.kabupatenKota();
                dataPasien['kecamatan'] = nikParser2.kecamatan();

                // dataBpjsRujukan.peserta.mr.noMR = "";
                // console.log("dataBpjsRujukan====", dataBpjsRujukan)

                if (this.state.dataAntrean.poli_bpjs !== dataBpjsRujukan.poliRujukan.kode) {
                    this.kaloError(`Poli Antrean Tidak Sama Dengan Poli Tujuan.`);
                    this.setState({ loading: false, dataBpjsRujukan });
                } else {
                    
                    ApiSimrs.get(`/bpjs/peserta/nokartu/${dataBpjsRujukan.peserta.noKartu}`, { headers: { jenis_faskes: '1' } })
                    .then((result3) => {
                        this.setState({ dataBpjsPeserta: result3.data }, () => {

                            if (dataBpjsRujukan.peserta.mr.noMR === "" || !dataBpjsRujukan.peserta.mr.noMR) {
                                this.setState({ loading: true }, () => {
                                    ApiSimrs.get(`/antrean/checkin/barulama`, {
                                        headers: {
                                            nik: dataBpjsRujukan.peserta.nik,
                                            no_bpjs: dataBpjsRujukan.peserta.noKartu,
                                            tgl_lahir: dataBpjsRujukan.peserta.tglLahir,
                                            nama_pelpasien: dataBpjsRujukan.peserta.nama,
                                        },
                                    })
                                    .then((result2) => {
                                        if (result2.data === "") {
                                            this.setState({
                                                dataBpjsRujukan,
                                                dataPasien: {
                                                    ...dataPasien,
                                                    nama_pelpasien: dataBpjsRujukan.peserta.nama,
                                                    tgl_lahir: dataBpjsRujukan.peserta.tglLahir,
                                                    hp: dataBpjsRujukan.peserta.mr.noTelepon,
                                                    jkel: dataBpjsRujukan.peserta.sex,
                                                    nik: dataBpjsRujukan.peserta.nik,
                                                    no_bpjs: dataBpjsRujukan.peserta.noKartu,
                                                    id_peluser: 1,
                                                },

                                                status_pasien: 1,
                                                loading: false,
                                                openPasienBaru: true,
                                            });
                                        } else {
                                            dataPasien = { ...dataPasien, ...result2.data };
                                            dataBpjsRujukan.peserta.mr.noMR = dataPasien.no_rekmedis;
                                            this.setState({
                                                dataBpjsRujukan,
                                                dataPasien,

                                                status_pasien: 0,
                                                loading: false,
                                            });
                                        };
                                    })
                                    .catch((error) => {
                                        this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
                                    });
                                });
                            } else {
                                dataPasien = { ...dataPasien, no_rekmedis: dataBpjsRujukan.peserta.mr.noMR };
                                this.setState({
                                    dataBpjsRujukan,
                                    dataPasien,
                                    
                                    status_pasien: 0,
                                    loading: false
                                });
                            };
                        });
                    })
                    .catch((error) => {
                        this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
                    });
                };
            })
            .catch((error) => {
                this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
            });
        });
    }

    simpan = () => {
        const {
            dataBpjsRujukan,
            dataPasien,
            dataAntrean,

            bpjs_no_rujukan,
            status_pasien,
        } = this.state;

        
        if (status_pasien === 1) {
            if (!dataPasien.tempat_lahir || dataPasien.tempat_lahir === "") {
                return Modal.warn({
                    content: `Pasien Baru : Tempat Lahir Harus Diisi.`,
                });
            };
            if (!dataPasien.alamat || dataPasien.alamat === "") {
                return Modal.warn({
                    content: `Pasien Baru : Alamat Harus Diisi.`,
                });
            };
            if (!dataPasien.agama || dataPasien.agama === "") {
                return Modal.warn({
                    content: `Pasien Baru : Agama Harus Diisi.`,
                });
            };
            if (!dataPasien.pendidikan || dataPasien.pendidikan === "") {
                return Modal.warn({
                    content: `Pasien Baru : Pendidikan Harus Diisi.`,
                });
            };
            if (!dataPasien.status_pernikahan || dataPasien.status_pernikahan === "") {
                return Modal.warn({
                    content: `Pasien Baru : Status Pernikahan Harus Diisi.`,
                });
            };
            if (!dataPasien.wilayah || dataPasien.wilayah === "") {
                return Modal.warn({
                    content: `Pasien Baru : Wilayah Harus Diisi.`,
                });
            };
        };

        this.setState({ loading: true }, () => {
            // console.log("guguk===", dataBpjsRujukan)
            ApiSimrs.post(`/antrean/checkin/bpjs/fktp`, {
                dataAntrean,
                dataBpjsRujukan,
                dataPasien,

                bpjs_no_rujukan,
                status_pasien,
            })
            .then((result) => {
                // console.log("masuk====", result.data)
                const dataSepInsert = result.data;
                const dataBpjsSepCari = this.state.dataBpjsSepCari;

                dataBpjsSepCari.noSep = dataSepInsert.noSep;
                dataBpjsSepCari.tglSep = dataSepInsert.tglSep;
                dataBpjsSepCari.peserta.jnsPeserta = dataSepInsert.peserta.jnsPeserta;
                dataBpjsSepCari.peserta.noKartu = dataSepInsert.peserta.noKartu;
                dataBpjsSepCari.peserta.noMr = dataSepInsert.peserta.noMr;
                dataBpjsSepCari.cob = '0';
                dataBpjsSepCari.peserta.nama = dataSepInsert.peserta.nama;
                dataBpjsSepCari.peserta.kelamin = dataSepInsert.peserta.kelamin;
                dataBpjsSepCari.jnsPelayanan = dataSepInsert.jnsPelayanan;
                dataBpjsSepCari.peserta.tglLahir = dataSepInsert.peserta.tglLahir;
                dataBpjsSepCari.poli = dataSepInsert.poli;
                dataBpjsSepCari.dpjp.kdDPJP = dataAntrean.dokter_bpjs;
                dataBpjsSepCari.dpjp.nmDPJP = dataAntrean.nama_dokter;
                dataBpjsSepCari.peserta.hakKelas = dataSepInsert.peserta.hakKelas;
                dataBpjsSepCari.diagnosa = dataSepInsert.diagnosa;
                dataBpjsSepCari.penjamin = dataSepInsert.penjamin;
                dataBpjsSepCari.catatan = dataSepInsert.catatan;

                this.setState({ dataBpjsSepCari }, () => {
                    document.getElementById('print-button').click();
                });
            })
            .catch((error) => {
                // console.log("keluar===", error.response);
                this.setState({ loading: false });
                Modal.warning({
                    title: 'Warning',
                    content: error.response.data
                });
            });
        });
    }
    
    render() {
        const { navigate, componentRef } = this.props;
        const {
            dataBpjsSepCari,
            dataBpjsPeserta,
            dataBpjsRujukan,
            dataPasien,
            dataCheckin,

            status_pasien,
            openPasienBaru
        } = this.state;
        return (
            <>
                <style>{pageStyle}</style>
                <Button type="link" href="/#/checkin" className="border border-black w-full mb-5 bg-red-500 text-white font-bold">Kembali</Button>
                <div className="grid grid-cols-3 gap-4 mb-2">
                    <div className="col-span-1">
                        <Input.Search
                            placeholder="Masukkan No. Rujukan"
                            size="large"
                            onChange={(e) => {
                                this.setState({ bpjs_no_rujukan: e.target.value });
                                const value = e.target.value;
                                if (value.length === 19) {
                                    this.fetchRujukan(value);
                                };
                            }}
                            disabled={this.state.loading}
                            className="mb-2"
                            autoFocus
                            maxLength={19}
                            loading={this.state.loading}
                        />
                        <Card>
                            <Antrean
                                data={this.state.dataAntrean}
                                tampil={true}
                            />
                        </Card>
                    </div>
                    <div className="col-span-2">
                        <Card>
                            {
                                dataBpjsRujukan.noKunjungan ?
                                    <table className="w-full" style={{ fontSize: '40px' }} cellPadding={3}>
                                        <tbody>
                                            <tr>
                                                <td>NIK</td>
                                                <td>:</td>
                                                <td>{dataBpjsRujukan.peserta.nik}</td>
                                            </tr>
                                            <tr>
                                                <td>Nama Pasien</td>
                                                <td>:</td>
                                                <td>{dataBpjsRujukan.peserta.nama}</td>
                                            </tr>
                                            <tr>
                                                <td>Tgl. Lahir</td>
                                                <td>:</td>
                                                <td>{dataBpjsRujukan.peserta.tglLahir}</td>
                                            </tr>
                                            <tr>
                                                <td>No. Kartu</td>
                                                <td>:</td>
                                                <td>{dataBpjsRujukan.peserta.noKartu}</td>
                                            </tr>
                                            <tr>
                                                <td valign="top">PPK1 / Puskesmas Perujuk</td>
                                                <td valign="top">:</td>
                                                <td valign="top">{dataBpjsRujukan.provPerujuk.nama}</td>
                                            </tr>
                                            <tr>
                                                <td>Poli Tujuan</td>
                                                <td>:</td>
                                                <td>{dataBpjsRujukan.poliRujukan.nama}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </Card>
                    </div>
                </div>
                {
                    status_pasien === 0 || status_pasien === 1 ?
                        <div className="w-full grid grid-cols-3 gap-4">
                            <Card
                                hoverable
                                className={`bg-blue-600 text-white text-center font-bold col-span-${status_pasien === 1 ? '2' : '3'}`}
                                style={{ fontSize: 50 }}
                                onClick={this.simpan}
                            >CHECKIN</Card>
                            {
                                status_pasien === 1 ?
                                    <Card
                                        hoverable
                                        className="bg-red-600 text-white text-center font-bold col-span-1"
                                        style={{ fontSize: 50 }}
                                        onClick={() => this.setState({ openPasienBaru: true })}
                                    >DATA PASIEN</Card>
                                : null
                            }
                        </div>
                    : null
                }
                <div
                    style={{ display: "none" }}
                >
                    <ReactToPrint
                        trigger={() => <button id="print-button">Print</button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            navigate(`/checkin`)
                        }}
                    />
                    <CetakSep
                        ref={componentRef}
                        dataBpjsSepCari={dataBpjsSepCari}
                        dataBpjsPeserta={dataBpjsPeserta}
                        dataBpjsRujukan={dataBpjsRujukan}
                        dataCheckin={{ ...dataCheckin, nomorantrean: `${this.state.dataAntrean.kode}-${this.state.dataAntrean.nomorantrean}` }}
                    />
                </div>
                {
                    openPasienBaru ?
                        <PasienBaru
                            dataPasien={dataPasien}
                            dataBpjsRujukan={dataBpjsRujukan}
                            onClose={() => this.setState({ openPasienBaru: false })}
                            onChangeDataPasien={(field, value) => {
                                dataPasien[field] = value;
                                this.setState({ dataPasien });
                            }}
                        />
                    : null
                }
            </>
        );
    };

};

export default withNavigate(index);
