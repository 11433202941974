import React, { useRef } from 'react';
import { useParams } from "react-router-dom";
import ApiSimrs from '../../config/ApiSimrs';
import { Col, Row, Card, Modal, Button } from 'antd';
import CetakAntrean from '../../components/CetakAntrean';
import ReactToPrint from 'react-to-print';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Dokter from "./Dokter";

const withNavigate = Component => props => {
    const navigate = useNavigate();
    const componentRef = useRef();
    const { id_pelpoli } = useParams();
    return <Component
        {...props}
        navigate={navigate}
        componentRef={componentRef}
        id_pelpoli={id_pelpoli}
    />;
};

class index extends React.Component {

    state = {
        data: [],
        dataAntrean: {
            id_antrean: null,
            nama_pelpoli: null,
            nama_dokter: null,
            tanggal: null,
            buka: null,
            tutup: null,
            nomorantrean: null,
        },

        openDokter: false,
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.mounted = true;
        const theDay = new Date().getDay();

        ApiSimrs.get(`/poliklinik/${this.props.id_pelpoli}/jadwaldokter?aktif=true`, {
            headers: {
                tgl_sekarang: moment(new Date()).format('YYYY-MM-DD')
            }
        })
        .then((result) => {
            const data = result.data.filter((row) => row.hari === (theDay === 0 ? 7 : theDay));
            const data2 = data.sort((a, b) => {
                if ( a.buka < b.buka ){
                    return -1;
                };
                if ( a.buka > b.buka ){
                    return 1;
                };
                return 0;
            });
            if(this.mounted) {
                this.setState({ data: data2 });
            };
        })
        .catch((error) => {
            console.log("keluar===", error.response);
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onPrint = (row) => {
        ApiSimrs.post(`/antrean/onsite`, {
            ...row,
            id_pelpolijadwal: row.id,
            tgl_sekarang: moment(new Date()).format('YYYY-MM-DD'),
        })
        .then((result) => {
            
            ApiSimrs.get(`/antrean/${result.data.id_antrean}`)
            .then((result) => {
                this.setState({ dataAntrean: { ...result.data, tanggal: moment(result.data.tgl).format('dddd, Do MMMM YYYY, HH:mm:ss') } });
                document.getElementById('print-button').click();
            })
            .catch((error) => {
                console.log("keluar===", error.response);
            });
        })
        .catch((error) => {
            // console.log("keluar===", error.response);
            Modal.warning({
                title: 'Warning',
                content: error.response.data,
            });
        });
    }
    
    render() {
        const { componentRef, navigate } = this.props;
        const { data, dataAntrean, openDokter } = this.state;
        return (
            <>
                <div className="mb-2">
                    <Button onClick={() => this.setState({ openDokter: true })}>PILIH DOKTER</Button>
                </div>
                <Row gutter={[16, 16]}>
                    {
                        data.map((row, i) => (
                            <Col key={i}>
                                <Card
                                    onClick={() => this.onPrint(row)}
                                    hoverable
                                    className="border-red-500"
                                >
                                    <div className="text-4xl text-center font-bold">{row.nama_dokter}</div>
                                    <div className="text-2xl text-center text-red-500">
                                        Jam: {`${row.buka.substring(0, 5)} - ${row.tutup.substring(0, 5)}`}
                                    </div>
                                    <div className="text-4xl text-center font-bold">Sisa Kuota: {row.sisaantrean}</div>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
                <div style={{ display: "none" }}>
                    <ReactToPrint
                        trigger={() => <button id="print-button">Print</button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            navigate(`/`)
                        }}
                    />
                    <CetakAntrean
                        ref={componentRef}
                        data={dataAntrean}
                    />
                </div>

                {
                    openDokter ?
                        <Dokter
                            close={() => this.setState({ openDokter: false }, () => this.fetchData())}
                            id_pelpoli={this.props.id_pelpoli}
                        />
                    : null
                }
            </>
        );
    }

};

export default withNavigate(index);
