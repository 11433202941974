import React from 'react';
import { useParams, Link } from "react-router-dom";
import { Col, Row, Card, Button } from 'antd';

const ColumnCustom = ({ data }) => (
    <Col span={12}>
        <Link to={data.link ? data.link : ''}>
            <Card
                hoverable
                style={{ fontSize: 80, fontWeight: 'bold' }}
                className={`${data.color} text-white text-center`}
            >
                {data.name}
            </Card>
        </Link>
    </Col>
);

const Index = () => {
    
    const { id_antrean } = useParams();
    
    return (
        <>
            <Button type="link" href="/#/checkin" className="border border-black w-full mb-5 bg-red-500 text-white font-bold">Kembali</Button>
            <Row gutter={[16, 24]}>
                <ColumnCustom
                    data={{
                        link: `/checkin/${id_antrean}/bpjs/fktp`,
                        name: 'RUJUKAN FKTP',
                        color: 'bg-blue-500',
                    }}
                />
                {/* <ColumnCustom
                    data={{
                        link: `/checkin/${id_antrean}/bpjs/internal`,
                        name: 'RUJUKAN INTERNAL',
                        color: 'bg-red-200',
                    }}
                /> */}
                <ColumnCustom
                    data={{
                        link: `/checkin/${id_antrean}/bpjs/kontrol`,
                        name: 'KONTROL ULANG',
                        color: 'bg-red-500',
                    }}
                />
                {/* <ColumnCustom
                    data={{
                        link: `/checkin/${id_antrean}/bpjs/rs`,
                        name: 'RUJUKAN ANTAR RS',
                        color: 'bg-blue-200',
                    }}
                /> */}
            </Row>
        </>
    );

};

export default Index;
