import React from 'react';
import { Content, Header, Footer } from '../components/Layout'
import GetTokenSimrs from '../config/GetTokenSimrs';
// import GetTokenAntrean from '../config/GetTokenAntrean';
import { Skeleton } from 'antd';
import Routes from '../config/Routes';

class Main extends React.Component {
  
    state = {
        loading: false,
    }
    
    componentDidMount() {
        this.setState({ loading: true }, () => {
            GetTokenSimrs()
            .then((result) => {
                
                localStorage.setItem('tokenSimrs', result.data.token);
                this.setState({ loading: false });
                // GetTokenAntrean()
                // .then((result2) => {
                //     if (result2.data.metadata.code === 201) {
                //         console.log("keluarTokenAntrean===", result2.data.metadata.message);
                //         localStorage.clear();
                //         this.setState({ loading: false });
                //     } else {
                //         localStorage.setItem('tokenAntrean', result2.data.response.token);
                //         localStorage.setItem('tokenSimrs', result.data.token);
                //         this.setState({ loading: false });
                //     };
                // })
                // .catch((error) => {
                //     console.log("keluarTokenAntrean===", error.response);
                //     localStorage.clear();
                //     this.setState({ loading: false });
                // });
            })
            .catch((error) => {
                console.log("keluarTokenSimrs===", error.response);
                localStorage.clear();
                this.setState({ loading: false });
            });
        });
    }
    
    render() {
        const { loading } = this.state;
        return (
            <>
                <Header />
                <Content>
                    {
                        loading ? <Skeleton active /> :
                        <Routes />
                    }
                </Content>
                <Footer />
            </>
        );
    }

};

export default (Main);
