import React from 'react';
import Clock from 'react-live-clock';
import idLocale from 'moment/locale/id';

class index extends React.Component {
    
    render() {
        return (
            <Clock
                format={'dddd, Do MMMM YYYY, HH:mm:ss'}
                ticking={true}
                timezone={'Asia/Jakarta'}
                locale="id"
                className="text-xl font-serif font-bold"
            />
        );
    }

};

export default (index);
