import React, { useRef } from 'react';
import { Input, Modal, Card, Button } from 'antd';
import ReactToPrint from 'react-to-print';
import CetakSep from '../../../../../components/CetakSep';
import { useNavigate, useParams } from "react-router-dom";
import ApiSimrs from '../../../../../config/ApiSimrs';
import Antrean from "../../../../../components/CetakAntrean";

const withNavigate = Component => props => {
    const navigate = useNavigate();
    const componentRef = useRef();
    const { id_antrean } = useParams();
    return <Component
        {...props}
        navigate={navigate}
        componentRef={componentRef}
        id_antrean={id_antrean}
    />;
};

const dataBpjsKontrolTmp = {
    noSuratKontrol: null,
    tglRencanaKontrol: null,
    tglTerbit: null,
    jnsKontrol: null,
    poliTujuan: null,
    namaPoliTujuan: null,
    kodeDokter: null,
    namaDokter: null,
    flagKontrol: null,
    kodeDokterPembuat: null,
    namaDokterPembuat: null,
    namaJnsKontrol: null,
    sep: {
        noSep: null,
        tglSep: null,
        jnsPelayanan: null,
        poli: null,
        diagnosa: null,
        peserta: {
            noKartu: null,
            nama: null,
            tglLahir: null,
            kelamin: null,
            hakKelas: null
        },
        provUmum: {
            kdProvider: null,
            nmProvider: null
        },
        provPerujuk: {
            kdProviderPerujuk: null,
            nmProviderPerujuk: null,
            asalRujukan: null,
            noRujukan: null,
            tglRujukan: null
        }
    }
};
const dataBpjsRujukanTmp = {
    diagnosa: {
       kode: null,
       nama: null
    },
    keluhan: null,
    noKunjungan: null,
    pelayanan: {
       kode: null,
       nama: null
    },
    peserta: {
        cob: {
            nmAsuransi: null,
            noAsuransi: null,
            tglTAT: null,
            tglTMT: null
        },
        hakKelas: {
            keterangan: null,
            kode: null
        },
        informasi: {
            dinsos: null,
            noSKTM: null,
            prolanisPRB: null
        },
        jenisPeserta: {
            keterangan: null,
            kode: null
        },
        mr: {
            noMR: null,
            noTelepon: null
        },
        nama: null,
        nik: null,
        noKartu: null,
        pisa: null,
        provUmum: {
            kdProvider: null,
            nmProvider: null
        },
        sex: null,
        statusPeserta: {
            keterangan: null,
            kode: null
        },
        tglCetakKartu: null,
        tglLahir: null,
        tglTAT: null,
        tglTMT: null,
        umur: {
            umurSaatPelayanan: null,
            umurSekarang: null
        }
    },
    poliRujukan: {
       kode: null,
       nama: null
    },
    provPerujuk: {
       kode: null,
       nama: null
    },
    tglKunjungan: null
};
const dataAntreanTmp = {
    poli_bpjs: null,
    dokter_bpjs: null,
    nama_dokter: null,
    kode: null,
    nomorantrean: null,
};
const dataPasienTmp = {
    no_rekmedis: null,
    tempat_lahir: null,
    agama: null,
    pendidikan: null,
    status_pernikahan: null,
    wilayah: null,
    alamat: null,
    kabupaten: null,
    kecamatan: null,
    nama_pelpasien: null,
    hp: null,
};
const dataBpjsPesertaTmp = {
    cob:{
       nmAsuransi:null,
       noAsuransi:null,
       tglTAT:null,
       tglTMT:null
    },
    hakKelas:{
       keterangan: null,
       kode: null
    },
    informasi:{
       dinsos:null,
       noSKTM:null,
       prolanisPRB:null
    },
    jenisPeserta:{
       keterangan:null,
       kode: null
    },
    mr:{
       noMR:null,
       noTelepon:null
    },
    nama: null,
    nik: null,
    noKartu: null,
    pisa: null,
    provUmum:{
       kdProvider: null,
       nmProvider: null
    },
    sex: null,
    statusPeserta:{
       keterangan: null,
       kode: null
    },
    tglCetakKartu: null,
    tglLahir: null,
    tglTAT: null,
    tglTMT: null,
    umur:{
       umurSaatPelayanan: null,
       umurSekarang: null
    }
};
const dataBpjsSepCariTmp = {
    noSep: null,
    tglSep: null,
    jnsPelayanan: null,
    kelasRawat: null,
    diagnosa: null,
    noRujukan: null,
    poli: null,
    poliEksekutif: null,
    catatan: null,
    penjamin: null,
    kdStatusKecelakaan: null,
    nmstatusKecelakaan: null,
    lokasiKejadian: {
        kdKab: null,
        kdKec: null,
        kdProp: null,
        ketKejadian: null,
        lokasi: null,
        tglKejadian: null
    },
    dpjp: {
        kdDPJP: null,
        nmDPJP: null
    },
    peserta: {
        asuransi: null,
        hakKelas: null,
        jnsPeserta: null,
        kelamin: null,
        nama: null,
        noKartu: null,
        noMr: null,
        tglLahir: null
    },
    klsRawat: {
        klsRawatHak: null,
        klsRawatNaik: null,
        pembiayaan: null,
        penanggungJawab: null
    },
    kontrol: {
        kdDokter: null,
        nmDokter: null,
        noSurat: null
    },
    cob: null,
    katarak: null
};
const dataCheckinTmp = {
    nomorantrean: null,
};

const pageStyle = `
    tr:nth-child(odd) {
        background-color: #ccc;
    }
    tr:nth-child(even) {
        background-color: #fff;
    }
`;

class index extends React.Component {
    
    state = {
        dataAntrean: dataAntreanTmp,
        dataBpjsKontrol: dataBpjsKontrolTmp,
        dataBpjsRujukan: dataBpjsRujukanTmp,
        dataBpjsSepCari: dataBpjsSepCariTmp,
        dataBpjsPeserta: dataBpjsPesertaTmp,
        dataPasien: dataPasienTmp,
        dataCheckin: dataCheckinTmp,

        bpjs_kontrol: null,

        loading: false,
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiSimrs.get(`/antrean/${this.props.id_antrean}`)
            .then((result) => {
                // console.log("dataAntrean====", result.data)
                this.setState({ dataAntrean: result.data, loading: false });
            })
            .catch((error) => {
                Modal.warning({
                    title: 'Warning',
                    content: error.response.data
                });
            });
        });
    }

    kaloError = (data) => {
        this.setState({
            loading: false,
        }, () => {
            Modal.warn({
                content: data,
                onOk: () => this.setState({ dataBpjsKontrol: dataBpjsKontrolTmp }),
            });
        });
    }

    fetchKontrol = (bpjs_kontrol) => {
        let { dataPasien } = this.state;
        
        this.setState({ loading: true }, () => {
            ApiSimrs.get(`/bpjs/rencana-kontrol/no-kontrol/${bpjs_kontrol}`)
            .then((result) => {
                // console.log(result.data)
                const dataBpjsKontrol = result.data;
                if (this.state.dataAntrean.poli_bpjs !== dataBpjsKontrol.poliTujuan) {
                    this.kaloError(`Poli Antrean Tidak Sama Dengan Poli Tujuan.`);
                    this.setState({ loading: false, dataBpjsKontrol });
                } else if (this.state.dataAntrean.dokter_bpjs !== dataBpjsKontrol.kodeDokter) {     
                    this.kaloError(`Dokter Antrean Tidak Sama Dengan Dokter Tujuan.`);
                    this.setState({ loading: false, dataBpjsKontrol });
                } else {
                    
                    ApiSimrs.get(`/bpjs/peserta/nokartu/${dataBpjsKontrol.sep.peserta.noKartu}`)
                    .then((result3) => {
                        
                        ApiSimrs.get(`/bpjs/sep/${dataBpjsKontrol.sep.noSep}`)
                        .then((result4) => {
                            
                            const dataBpjsSepCari = result4.data;
                            ApiSimrs.get(`/bpjs/rujukan/norujukan/pc/${dataBpjsSepCari.noRujukan}`)
                            .then((result5) => {
                                
                                const dataBpjsRujukan = result5.data;
                                this.setState({ dataBpjsPeserta: result3.data }, () => {
                                    dataPasien = {
                                        ...dataPasien,
                                        no_rekmedis: result3.data.mr.noMR,
                                        hp: result3.data.mr.noTelepon,
                                    };
                                    this.setState({
                                        dataBpjsKontrol,
                                        dataPasien,
                                        dataBpjsSepCari,
                                        dataBpjsRujukan,
                                        
                                        loading: false
                                    });
                                });
                            })
                            .catch((error) => {
                                this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
                            });
                        })
                        .catch((error) => {
                            this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
                        });
                    })
                    .catch((error) => {
                        this.kaloError(`Coba Lagi / Daftar Di Pendaftaran`);
                    });
                };
            })
            .catch((error) => {
                // console.log(error.response)
                this.kaloError(error.response.data);
            });
        });
    }

    simpan = () => {
        const {
            dataBpjsKontrol,
            dataPasien,
            dataAntrean,
            dataBpjsSepCari,
            dataBpjsRujukan,

            bpjs_kontrol,
        } = this.state;

        this.setState({ loading: true }, () => {
            // console.log("guguk===", dataBpjsKontrol)
            ApiSimrs.post(`/antrean/checkin/bpjs/kontrol/konsul`, {
                dataAntrean,
                dataBpjsKontrol,
                dataPasien,
                dataBpjsRujukan,

                bpjs_kontrol,
            })
            .then((result) => {
                // console.log("masuk====", result.data)
                const dataSepInsert = result.data;

                dataBpjsSepCari.noSep = dataSepInsert.noSep;
                dataBpjsSepCari.tglSep = dataSepInsert.tglSep;
                dataBpjsSepCari.peserta.jnsPeserta = dataSepInsert.peserta.jnsPeserta;
                dataBpjsSepCari.peserta.noKartu = dataSepInsert.peserta.noKartu;
                dataBpjsSepCari.peserta.noMr = dataSepInsert.peserta.noMr;
                dataBpjsSepCari.cob = '0';
                dataBpjsSepCari.peserta.nama = dataSepInsert.peserta.nama;
                dataBpjsSepCari.peserta.kelamin = dataSepInsert.peserta.kelamin;
                dataBpjsSepCari.jnsPelayanan = dataSepInsert.jnsPelayanan;
                dataBpjsSepCari.peserta.tglLahir = dataSepInsert.peserta.tglLahir;
                dataBpjsSepCari.poli = dataSepInsert.poli;
                dataBpjsSepCari.dpjp.kdDPJP = dataAntrean.dokter_bpjs;
                dataBpjsSepCari.dpjp.nmDPJP = dataAntrean.nama_dokter;
                dataBpjsSepCari.peserta.hakKelas = dataSepInsert.peserta.hakKelas;
                dataBpjsSepCari.diagnosa = dataSepInsert.diagnosa;
                dataBpjsSepCari.penjamin = dataSepInsert.penjamin;
                dataBpjsSepCari.catatan = dataSepInsert.catatan;

                this.setState({ dataBpjsSepCari }, () => {
                    document.getElementById('print-button').click();
                });
            })
            .catch((error) => {
                console.log("keluar===", error.response);
                this.setState({ loading: false });
                // Modal.warning({
                //     title: 'Warning',
                //     content: error.response.data
                // });
            });
        });
    }
    
    render() {
        const { navigate, componentRef } = this.props;
        const {
            dataBpjsSepCari,
            dataBpjsPeserta,
            dataBpjsKontrol,
            dataBpjsRujukan,
            dataPasien,
            dataCheckin,
        } = this.state;
        return (
            <>
                <style>{pageStyle}</style>
                <Button type="link" href="/#/checkin" className="border border-black w-full mb-5 bg-red-500 text-white font-bold">Kembali</Button>
                <div className="grid grid-cols-3 gap-4 mb-2">
                    <div className="col-span-1">
                        <Input.Search
                            placeholder="Masukkan No. Kontrol"
                            size="large"
                            onChange={(e) => {
                                this.setState({ bpjs_kontrol: e.target.value });
                                const value = e.target.value;
                                if (value.length === 19) {
                                    this.fetchKontrol(value);
                                };
                            }}
                            disabled={this.state.loading}
                            className="mb-2"
                            autoFocus
                            maxLength={19}
                            loading={this.state.loading}
                        />
                        <Card>
                            <Antrean
                                data={this.state.dataAntrean}
                                tampil={true}
                            />
                        </Card>
                    </div>
                    <div className="col-span-2">
                        <Card>
                            {
                                dataBpjsKontrol.noSuratKontrol ?
                                    <table className="w-full" style={{ fontSize: '40px' }} cellPadding={3}>
                                        <tbody>
                                            <tr>
                                                <td>Nama Pasien</td>
                                                <td>:</td>
                                                <td>{dataBpjsKontrol.sep.peserta.nama}</td>
                                            </tr>
                                            <tr>
                                                <td>No. Rekmedis</td>
                                                <td>:</td>
                                                <td>{dataPasien.no_rekmedis}</td>
                                            </tr>
                                            <tr>
                                                <td>Tgl. Lahir</td>
                                                <td>:</td>
                                                <td>{dataBpjsKontrol.sep.peserta.tglLahir}</td>
                                            </tr>
                                            <tr>
                                                <td>No. Kartu</td>
                                                <td>:</td>
                                                <td>{dataBpjsKontrol.sep.peserta.noKartu}</td>
                                            </tr>
                                            <tr>
                                                <td valign="top">Nama Dokter</td>
                                                <td valign="top">:</td>
                                                <td valign="top">{dataBpjsKontrol.namaDokter}</td>
                                            </tr>
                                            <tr>
                                                <td>Poli Tujuan</td>
                                                <td>:</td>
                                                <td>{dataBpjsKontrol.namaPoliTujuan}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                : null
                            }
                        </Card>
                    </div>
                </div>
                <Card
                    hoverable
                    className={`bg-blue-600 text-white text-center font-bold`}
                    style={{ fontSize: 50 }}
                    onClick={this.simpan}
                >CHECKIN</Card>
                <div style={{ display: "none" }}>
                    <ReactToPrint
                        trigger={() => <button id="print-button">Print</button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            navigate(`/checkin`)
                        }}
                    />
                    <CetakSep
                        ref={componentRef}
                        dataBpjsSepCari={dataBpjsSepCari}
                        dataBpjsPeserta={dataBpjsPeserta}
                        dataBpjsRujukan={dataBpjsRujukan}
                        dataCheckin={{ ...dataCheckin, nomorantrean: `${this.state.dataAntrean.kode}-${this.state.dataAntrean.nomorantrean}` }}
                    />
                </div>
            </>
        );
    };

};

export default withNavigate(index);
