import React from 'react';

const pageStyle = `
    @page {
        size: A4 potrait;
        margin-left: 10mm;
        margin-top: 10mm;
        margin-right: 10mm;
    }
`;

class index extends React.Component {
   
    render() {
        const {
            dataBpjsSepCari,
            dataBpjsPeserta,
            dataBpjsRujukan,
            dataSepInternal,
            dataCheckin,
        } = this.props;
        return (
            <div>
                <style>{pageStyle}</style>
                <table className="w-full">
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src={require('../../assets/imgs/logo-bpjs.png')}
                                    style={{width: 200, height: 'auto',}}
                                    alt=""
                                />
                            </td>
                            <td>
                                <div>SURAT ELEGIBILITAS PESERTA</div>
                                <div>RS. PERTAMEDIKA UMMI ROSNATI</div>
                            </td>
                            <td>
                                <h3 style={{ fontSize: '1rem' }}>
                                    {
                                        dataBpjsPeserta.informasi.prolanisPRB ? `Pasien ${dataBpjsPeserta.informasi.prolanisPRB}` : ''
                                    }
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <table className="w-full">
                                    <tbody>
                                    <tr>
                                        <td>No. SEP</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.noSep}
                                        </td>
                                        <td colSpan={3}></td>
                                    </tr>
                                    <tr>
                                        <td>Tgl. SEP</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.tglSep}
                                        </td>
                                        <td>Peserta</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.peserta.jnsPeserta}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>No.Kartu</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.peserta.noKartu} ( MR. {dataBpjsSepCari.peserta.noMr} )
                                        </td>
                                        <td>COB</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.cob === "0" ? "-" : dataBpjsSepCari.cob}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nama Peserta</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.peserta.nama} ({dataBpjsSepCari.peserta.kelamin})
                                        </td>
                                        <td>Jns.Rawat</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.jnsPelayanan}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tgl.Lahir</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.peserta.tglLahir}
                                        </td>
                                        {
                                            dataSepInternal ?
                                                <>
                                                    <td>Jns. Kunjungan</td>
                                                    <td>:</td>
                                                    <td>
                                                        {dataSepInternal ? "- Kunjungan Rujukan Internal -" : ""}
                                                    </td>
                                                </>
                                            : <td colSpan={3}></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>No.Telepon</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsPeserta.mr.noTelepon}
                                        </td>
                                        {
                                            dataSepInternal ?
                                                <>
                                                    <td></td>
                                                    <td>:</td>
                                                    <td>
                                                        {dataSepInternal.flagprosedur === "0" ? "- Prosedur Tidak Berkelanjutan -" : ""}
                                                    </td>
                                                </>
                                            : <td colSpan={3}></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>Sub/Spesialis</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.poli}
                                        </td>
                                        {
                                            dataSepInternal ?
                                                <>
                                                    <td>Poli Perujuk</td>
                                                    <td>:</td>
                                                    <td>
                                                        {dataSepInternal.nmpoliasal}
                                                    </td>
                                                </>
                                            : <td colSpan={3}></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>DPJP Yg Melayani</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.dpjp.kdDPJP} - {dataBpjsSepCari.dpjp.nmDPJP}
                                        </td>
                                        <td>Hak Kelas</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.peserta.hakKelas}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Faskes Perujuk</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsRujukan.provPerujuk.kode} - {dataBpjsRujukan.provPerujuk.nama}
                                        </td>
                                        {
                                            dataBpjsSepCari.jnsPelayanan === "Rawat Inap" ?
                                                <>
                                                    <td>Kls.Rawat</td>
                                                    <td>:</td>
                                                    <td>{dataBpjsSepCari.kelasRawat}</td>
                                                </>
                                            : <td colSpan={3}></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>Diagnosa Awal</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.diagnosa}
                                        </td>
                                        <td>Penjamin</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.penjamin}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Catatan</td>
                                        <td>:</td>
                                        <td>
                                            {dataBpjsSepCari.catatan}
                                        </td>
                                        <td colSpan={3}></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: '12px' }} className="text-gray-600">
                                *Saya Menyetujui BPJS Kesehatan menggunakan informasi medis pasien jika diperlukan.<br />
                                *SEP Bukan sebagai bukti penjaminan peserta. 
                            </td>
                            <td align="right">Pasien/Keluarga Pasien</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: '12px' }}>
                                Cetakan : {new Date().toISOString().substr(0, 10)} {new Date().getHours()}{new Date().toISOString().substr(13, 6)}
                            </td>
                            <td align="right">____________________</td>
                        </tr>
                    </tbody>
                </table>
                <div className="font-bold text-2xl">
                    NOMOR ANTREAN: {dataCheckin.nomorantrean}
                </div>
            </div>
        );
   }
}

export default (index);
