import React from "react";
import { HashRouter, Routes as Router, Route } from "react-router-dom";

import PilihPoli from "../../views/PilihPoli";
import PilihDokter from "../../views/PilihDokter";

import Checkin from "../../views/Checkin";
import CheckinBpjs from "../../views/Checkin/Bpjs";
import CheckinBpjsFktp from "../../views/Checkin/Bpjs/Fktp";
import CheckinBpjsInternal from "../../views/Checkin/Bpjs/Internal";

import CheckinBpjsKontrol from "../../views/Checkin/Bpjs/Kontrol";
import CheckinBpjsKontrolKonsul from "../../views/Checkin/Bpjs/Kontrol/Konsul";

import CheckinBpjsRs from "../../views/Checkin/Bpjs/Rs";

import Dashboard from "../../views/Dashboard";
import DashboardAntreanPendaftaran from "../../views/Dashboard/Antrean/Pendaftaran";
import DashboardAntreanPoliDalam from "../../views/Dashboard/Antrean/Poli/Dalam";
import DashboardAntreanPoliObgyn from "../../views/Dashboard/Antrean/Poli/Obgyn";
import DashboardAntreanPoliJantung from "../../views/Dashboard/Antrean/Poli/Jantung";

// import PilihPoliWa from "../../views/Wa";
// import PilihDokterWa from "../../views/Wa/PilihDokter";

const Routes = () => {
  return (
    <HashRouter>
      <Router>
        <Route path="/" element={<PilihPoli />} />
        <Route path="/pilihdokter/:id_pelpoli" element={<PilihDokter />} />

        <Route path="/checkin" element={<Checkin />} />
        <Route path="/checkin/:id_antrean/bpjs" element={<CheckinBpjs />} />
        <Route path="/checkin/:id_antrean/bpjs/fktp" element={<CheckinBpjsFktp />} />
        <Route path="/checkin/:id_antrean/bpjs/internal" element={<CheckinBpjsInternal />} />
        <Route path="/checkin/:id_antrean/bpjs/kontrol" element={<CheckinBpjsKontrol />} />
        <Route path="/checkin/:id_antrean/bpjs/kontrol/konsul" element={<CheckinBpjsKontrolKonsul />} />
        <Route path="/checkin/:id_antrean/bpjs/rs" element={<CheckinBpjsRs />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/antrean/pendaftaran" element={<DashboardAntreanPendaftaran />} />
        <Route path="/dashboard/antrean/poli/dalam" element={<DashboardAntreanPoliDalam />} />
        <Route path="/dashboard/antrean/poli/obgyn" element={<DashboardAntreanPoliObgyn />} />
        <Route path="/dashboard/antrean/poli/jantung" element={<DashboardAntreanPoliJantung />} />

        {/* <Route path="/wa" element={<PilihPoliWa />} />
        <Route path="/wa/pilihdokter/:id_pelpoli/:tgl" element={<PilihDokterWa />} /> */}
      </Router>
    </HashRouter>
  );
};

export default Routes;
