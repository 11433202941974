import React from 'react';
import socketIOClient from "socket.io-client";
import { Card } from 'antd';
import ReactPlayer from 'react-player';
import ApiSimrs from '../../../../config/ApiSimrs';
import moment from "moment";

class _Loket extends React.Component {
    
    state = {
        socket: socketIOClient(process.env.REACT_APP_URL_API_SIMRS),
        aktif: {
            kode: null,
            nama_dokter: null,
            nomorantrean: null,
            nama_pelpasien: null,
            no_rekmedis: null,
            dokter: null,
            url: [],
        },

        dataDokter: [],

        url: [],
        index: 0,
        playing: false,
        pencet: false,
    }
    
    componentDidMount() {
        this.fetchDokter();

        this.state.socket.on('PanggilAntreanPoli', aktif => {
            // console.log("masuk===", aktif)
            if (aktif.id_pelpoli === this.props.id_pelpoli) {

                const url = [];
                url.push(require(`../../../../assets/audios/antrean/female/${aktif.kode}.mp3`));
                JSON.stringify(aktif.nomorantrean).split('').forEach((row, i) => {
                    url.push(require(`../../../../assets/audios/antrean/female/${row}.mp3`));
                });
                url.push(require(`../../../../assets/audios/antrean/female/silahkan_masuk.mp3`));
                
                const dataDokter = this.state.dataDokter;
                dataDokter.map(row => {
                    if (row.id_pelpolijadwal === aktif.id_pelpolijadwal) {
                        row['kode'] = aktif.kode;
                        row['nomorantrean'] = aktif.nomorantrean;
                    };
                });
    
                this.setState({ aktif : { ...aktif, url }, dataDokter }, () => {
                    if (this.state.playing) {
                        return;
                    } else {
                        this.holdSong();
                    };
                });
            };
		});
	}

    fetchDokter = () => {
        const theDay = new Date().getDay();

        ApiSimrs.get(`/poliklinik/${this.props.id_pelpoli}/jadwaldokter`, {
            headers: {
                tgl_sekarang: moment(new Date()).format('YYYY-MM-DD')
            }
        })
        .then((result) => {
            const dataDokter = result.data.filter((row) => row.hari === (theDay === 0 ? 7 : theDay));
            // console.log("guguk====", dataDokter)
            this.setState({ dataDokter });
        })
        .catch((error) => {
            console.log("keluar===", error.response);
        });
    }
    
    componentWillUnmount() {
        this.state.socket.disconnect();
    }

    holdSong = () => {
        this.setState({ url: this.state.aktif.url, index: 0, playing: true });
    }

    playingPlay = () => {
        this.setState({ playing: true });
    }
    
    render() {
        const { aktif, dataDokter } = this.state;
        return (
            <>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div className="text-center mb-3 text-blue-500 font-bold border border-blue-500">
                            <span style={{ fontSize: '5vw' }}>{aktif.nama_dokter}</span>
                        </div>
                        <Card className="font-bold bg-green-100 text-center" style={{ fontSize: '14vw' }}>
                            <div>{aktif.kode}-{aktif.nomorantrean}</div>
                            <div style={{ fontSize: '3vw' }}>{aktif.nama_pelpasien} - {aktif.no_rekmedis}</div>
                        </Card>
                    </div>
                    <div>
                        <div className="text-center mb-3 text-blue-500 font-bold border border-blue-500">
                            <h1 style={{ fontSize: '5vw'}}>{this.props.nama_pelpoli.toUpperCase()}</h1>
                        </div>
                        {
                            dataDokter.map((row, i) => (
                                <div key={i} className="bg-green-100 flex justify-between p-3 mb-3">
                                    <span style={{ fontSize: '3vw' }}>{row.nama_dokter}</span>
                                    <span style={{ fontSize: '3vw' }} className="font-bold">{aktif.dokter === row.dokter ? `${aktif.kode}-${aktif.nomorantrean}` : `-`}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="hidden">
                    <ReactPlayer
                        key={this.state.index}
                        url={this.state.url[this.state.index]}
                        className="border border-black"
                        controls={true}
                        playing={this.state.playing}
                        onEnded={() => {
                            const indexPrev = this.state.index;
                            const indexEnd = this.state.url.length-1;
                            if (indexPrev === indexEnd) {
                                this.setState({ playing: false, index: 0 });
                            } else {
                                this.setState({ index: indexPrev + 1 });
                            };
                        }}
                    />
                </div>
            </>
        );
    }

};

export default _Loket;
