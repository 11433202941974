import React from 'react';
import { notification, Modal, Button, TimePicker, Select, Table } from 'antd';
import ApiSimrs from '../../config/ApiSimrs';
import moment from "moment";

class Dokter extends React.Component {
	
	state = {
        data: [],
        dataDokter: [],

        hari: null,
        buka: '08:00',
        tutup: '08:00',
        dokter_bpjs: null,
    }

	componentDidMount() {
        this.getDokter();
        this.fetchData();
    }
  
	fetchData = () => {
		const data = [];
        const theDay = new Date().getDay();
		ApiSimrs.get(`/poliklinik/${this.props.id_pelpoli}/jadwaldokter`, {
            headers: {
                tgl_sekarang: moment(new Date()).format('YYYY-MM-DD')
            }
        })
		.then(result => {
            const data = result.data.filter((row) => row.hari === (theDay === 0 ? 7 : theDay));
            const data2 = data.sort((a, b) => {
                if ( a.buka < b.buka ){
                    return -1;
                };
                if ( a.buka > b.buka ){
                    return 1;
                };
                return 0;
            });
            this.setState({ data: data2 });
        })
		.catch(error => {
			notification.error({
                message: 'Error',
                description: JSON.stringify(error.response.data),
            });
		});
	}

    getDokter = () => {
		ApiSimrs.get(`/pelayanan/user/dokter`)
		.then(result => {
			const dataDokter = result.data.filter((row) => row.kode_bpjs);
            this.setState({ dataDokter });
		})
		.catch(error => {
			console.log(error.response)
		});
	}

	simpan = () => {
        const {
            buka,
            tutup,
            dokter_bpjs,
        } = this.state;
        const theDay = new Date().getDay();
        this.setState({ loading: true }, () => {
            ApiSimrs.post(`/pelayanan/poli/jadwal`, {
                id_pelpoli: this.props.id_pelpoli,
                hari: theDay === 0 ? 7 : theDay,
                buka,
                tutup,
                dokter_bpjs,
            })
            .then(result => {
                this.fetchData();
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: JSON.stringify(error.response.data),
                });
                this.setState({ loading: false });
            });
        });
    }

    hapus = (id_pelpolijadwal) => {
        this.setState({ loading: true }, () => {
            ApiSimrs.delete(`/pelayanan/poli/jadwal/${id_pelpolijadwal}`)
            .then(result => {
                this.fetchData();
            })
            .catch(error => {
                if (error.response.data === "Cannot delete or update a parent row: a foreign key constraint fails (`rspur3`.`antrean`, CONSTRAINT `antrean_ID_PELPOLIJADWAL` FOREIGN KEY (`id_pelpolijadwal`) REFERENCES `pel_poli_jadwal` (`id`))") {
                    notification.error({
                        message: 'Error',
                        description: "Jadwal Sudah Terpakai Di Antrean, Soo.. Gk Bisa Dihapus..",
                    });
                };
                this.setState({ loading: false });
            });
        });
    }

    aktifkan = (id_pelpolijadwal) => {
        this.setState({ loading: true }, () => {
            ApiSimrs.patch(`/pelayanan/poli/jadwal/${id_pelpolijadwal}`, {
                hapus: 'false',
            })
            .then(result => {
                this.fetchData();
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: error.response.data,
                });
                this.setState({ loading: false });
            });
        });
    }

    nonaktifkan = (id_pelpolijadwal) => {
        this.setState({ loading: true }, () => {
            ApiSimrs.patch(`/pelayanan/poli/jadwal/${id_pelpolijadwal}`, {
                hapus: 'true',
            })
            .then(result => {
                this.fetchData();
            })
            .catch(error => {
                notification.error({
                    message: 'Error',
                    description: error.response.data,
                });
                this.setState({ loading: false });
            });
        });
    }
			
	render() {
		const { data } = this.state;
        return (
			<Modal
				visible={true}
				onCancel={this.props.close}
				width="80%"
				footer={false}
				title={`PILIH DOKTER`}
			>
				<div className="mb-2">
                    <Select
                        onChange={(dokter_bpjs) => this.setState({ dokter_bpjs })}
                        className="mr-2"
                        placeholder="-PILIH DOKTER-"
                        value={this.state.dokter_bpjs}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                        style={{ width: 600 }}
                    >
                        {this.state.dataDokter.map((row, i) => (<Select.Option value={row.kode_bpjs} key={i}>{row.nama_peluser}</Select.Option>))}
                    </Select>
                    BUKA: <TimePicker
                        format={"HH:mm"}
                        onSelect={(e) => this.setState({ buka: e.format('HH:mm') })}
                        className="mr-2"
                        value={moment(this.state.buka, 'HH:mm')}
                    />
                    TUTUP: <TimePicker
                        format={"HH:mm"}
                        onSelect={(e) => this.setState({ tutup: e.format('HH:mm') })}
                        className="mr-2"
                        value={moment(this.state.tutup, 'HH:mm')}
                    />
                    <Button onClick={this.simpan}>TAMBAH</Button>
                </div>
                <Table
					dataSource={data}
					size="large"
					pagination={false}
					rowKey={(row) => row.id_pelpolijadwal}
					columns={[
						{
                            title: 'Action',
                            render: (dataIndex, row) => (
                                <div className="flex justify-between">
                                    {
                                        row.hapus ?
                                            <Button color="primary" className="mr-2" onClick={() => this.aktifkan(row.id_pelpolijadwal)}>AKTIFKAN</Button>
                                        :
                                            <Button color="primary" className="mr-2" onClick={() => this.nonaktifkan(row.id_pelpolijadwal)}>NON AKTIFKAN</Button>
                                    }
                                    <Button color="primary" className="mr-2" onClick={() => this.hapus(row.id_pelpolijadwal)}>HAPUS</Button>
                                </div>
                            ),
                        },
						{ title: 'Nama Dokter', dataIndex: 'nama_dokter', },
						{ title: 'Jam', render: (dataIndex, row) => `${row.buka.substring(0, 5)} - ${row.tutup.substring(0, 5)}` },
					]}
					bordered
                    rowClassName={(record, index) => record.hapus ? 'bg-red-200' : null}
				/>
			</Modal>
		)
	}

};

export default Dokter;
