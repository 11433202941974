import React from 'react';

class Content extends React.Component {
	
	render() {
		return (
      <div
        className="fixed top-20 w-full p-5"
      >
        {this.props.children}
      </div>
		)
	}

}

export default Content;
