import React from 'react';
// import Barcode from "react-barcode";
import QRCode from "react-qr-code";

// const pageStyle = `
//     @page {
//         size: 80mm 210mm;
//     }
// `;

class index extends React.Component {
    
    render() {
        const { data } = this.props;
        return (
            <div>
                {/* <style>{pageStyle}</style> */}
                <div className="w-full grid justify-items-center">
                    <div><img src={require('../../assets/imgs/logo-rs.png')} alt="" /></div>
                    <div>Poli. {data.nama_pelpoli} ({data.buka}-{data.tutup})</div>
                    <div>{data.nama_dokter}</div>
                    <div className="font-bold">LOKET {data.loket}</div>
                </div>
                <div className="relative flex justify-center mb-16">
                    <div className="absolute font-bold leading-none text-gray-400" style={{ fontSize: this.props.tampil ? '70px' : '25vw' }}>{data.kode}-{data.nomorantrean}</div>
                    <div className="absolute top-5 border border-black w-full text-center">
                        <p className="p-1" style={{ fontSize: 17 }}>{data.tanggal}</p>
                    </div>
                </div>
                {/* <br /><br /><br /><br /><br /> */}
                <div className="w-full flex justify-center">
                    {/* <Barcode value={data.kodebooking ? data.kodebooking : `null`} /> */}
                    <QRCode value={data.kodebooking ? data.kodebooking : `null`} size={100} />
                </div>
                <div className="w-full flex justify-center" style={{ fontSize: 20 }}>
                    {data.kodebooking}
                </div>
            </div>
        );
    }

};

export default index;
