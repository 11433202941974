import React from 'react';
import { Link } from "react-router-dom";
import ApiSimrs from '../../config/ApiSimrs';
import { Card } from 'antd';
import _ from 'lodash';

class index extends React.Component {
	_isMounted = false;
    
    state = {
        data: {},
    }
    
    componentDidMount() {
        this._isMounted = true;
		ApiSimrs.get(`/poliklinik`)
		.then((result) => {
            if (this._isMounted) {
				const data = _.chain(result.data)
                    .filter(row => row.loket)
                    .groupBy(row => row.loket )
                    .value();
                this.setState({ data });
			};
		})
		.catch((error) => {
			console.log("keluar===", error.response);
		});
    }

	componentWillUnmount() {
		this._isMounted = false;
	}
    
    render() {
        const { data } = this.state;
        return (
            <>
                <div className="grid grid-cols-3 gap-4">
                    
                    {
                        Object.keys(data).map((row, i) => (
                            <Card
                                className={`border-green-500`}
                                key={i}
                            >
                                <div className="text-4xl text-center font-bold mb-3">LOKET {row}</div>
                                {
                                    data[row].map((row, i) => (
                                        <Link to={`/pilihdokter/${row.id}`} key={i}>
                                            <div
                                                className={`bg-yellow-500 mb-1 text-2xl font-bold p-5`}
                                            >{row.nama.toUpperCase()}</div>
                                        </Link>
                                    ))
                                }
                            </Card>
                        ))
                    }
                </div>
            </>
        );
    }

};

export default (index);
